<template>
  <div class="dashboard1 p-5" id="dementie-information">
      <div class="row">
        <div class="col-sm-12 down">
            <h1 class="intro-section-title">Niet Pluis fase</h1>
            <div class="row">
              <p class="intro-section-text">
                Op deze pagina verzamelen wij de inzichten over mensen met dementie in de Niet Pluis fase: de fase waarin de eerste signalen komen dat iemand mogelijk aan dementie lijdt, zoals geheugenproblemen. Hoe eerder dergelijke signalen worden opgepikt door familie, vrienden of kennissen, hoe eerder de persoon met dementie de professionele hulp kan ontvangen waar hij of zij baat bij kan hebben. De komende tijd vullen wij deze pagina aan met nieuwe inzichten. Op dit moment kunt u informatie vinden over zorg en ondersteuning tijdens de niet pluis fase over de volgende thema's:
              </p>
            </div>

            <div class="row align-items-center justify-content-around link-group">
                <p a class="levenmet-text"><a class="levenmet-link" href="/Niet-Pluis/7">Overig</a></p>
            </div>
        </div>
      </div>
      <!-- The hr used to divide intro and footer in the below -->
      <!-- <hr class="rounded"> -->
      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Niet Pluis fase',
  },
  components: { Footer  },
  data() {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
    }
  },
}

</script>

<style scoped>

ul {
  list-style-type: none;
}

hr.rounded {
  border-top: 8px solid var(--color-12);
  border-radius: 5px;
}

.intro-section-colour-block {
background: #e3e7ed;
width: 100%;
padding: 5% 8% 8% 5%;
position: relative;
border: 5px solid #e3e7ed;
border-radius: 15px;
}


.section-space-divider {
  margin-top: 100px;

}

.intro-section-title{
font-weight: bold;
color: #1f2a44;
margin-bottom: 2rem;
}

.intro-section-text{
  color: #1f2a44;
  text-align: justify;
}


p,
.levenmet-link{
  color: var(--color-12);
}

.levenmet-link{
  font-weight: bold;
}

.p{
  margin: 30px 0 30px 0;
  font-weight: thin;
}

.levenmet-link:hover,
.levenmet-link:focus{
  outline: none;
  box-shadow: none;
  display: block;
  color: var(--color-11);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
} 

.levenmet-text{
  text-align: center;
}

.link-group{
  margin: 5rem;
}

.second-link-group{
  margin-top: 1rem;
}
</style>
